import config from 'next/config';
import {
  DirectDebitIcon,
  CreditCardIcon,
  PayPalIcon,
  ApplePayIcon,
  GooglePayIcon,
  RevolutIcon,
} from '../components/03_organisms/DonationForm/steps/06_PaymentsStep/PaymentMethodsIcons';
import { SD, RG, ENVIRONMENT, DEFAULT_COUNTRY_CODE } from './constants';

const {
  publicRuntimeConfig: {
    PAYMENT_SECRET_HEADER_NAME,
    STRIPE_PUBLISHABLE_TEST,
    STRIPE_PUBLISHABLE_LIVE,
    STRIPE_GIFTS_PUBLISHABLE_TEST,
    STRIPE_GIFTS_PUBLISHABLE_LIVE,
  },
} = config();

/**
 * Live payment mode.
 */
export const PAYMENT_MODE_LIVE = 'live';

/**
 * Test payment mode.
 */
export const PAYMENT_MODE_TEST = 'test';

export const PayPalSecuredLogo = {
  src: '/static/images/paypal-secured.png',
  alt: 'Secured by PayPal',
};

export const DirectDebitLogo = {
  src: '/static/images/directdebit-uk.jpg',
  alt: 'Direct Debit Guarantee',
};

const stripePublicKeys = {
  [PAYMENT_MODE_TEST]: STRIPE_PUBLISHABLE_TEST,
  [PAYMENT_MODE_LIVE]: STRIPE_PUBLISHABLE_LIVE,
};
const stripeGiftsPublicKeys = {
  [PAYMENT_MODE_TEST]: STRIPE_GIFTS_PUBLISHABLE_TEST,
  [PAYMENT_MODE_LIVE]: STRIPE_GIFTS_PUBLISHABLE_LIVE,
};

/**
 * Define available payment methods.
 *
 * @type {*[]}
 */
export const methods = [
  {
    id: 'direct_debit_live',
    label: 'Bank account',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: DEFAULT_COUNTRY_CODE === 'GB' ? 'direct_debit_uk' : 'direct_debit_sepa',
    defaults: {
      debit_date: DEFAULT_COUNTRY_CODE === 'GB' ? '1' : '2',
      accept_direct_debits: '1',
      one_signatory: '1',
    },
    icon: DirectDebitIcon,
  },
  {
    id: 'direct_debit_test',
    label: 'Bank account',
    mode: PAYMENT_MODE_TEST,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: DEFAULT_COUNTRY_CODE === 'GB' ? 'direct_debit_uk' : 'direct_debit_sepa',
    defaults: {
      debit_date: DEFAULT_COUNTRY_CODE === 'GB' ? '1' : '2',
      accept_direct_debits: '1',
      one_signatory: '1',
    },
    icon: DirectDebitIcon,
  },
  {
    id: 'decoupled_stripe_credit_card_live',
    label: 'Debit/Credit Card',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: CreditCardIcon,
  },
  {
    id: 'decoupled_stripe_credit_card_test',
    label: 'Debit/Credit Card',
    mode: PAYMENT_MODE_TEST,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: CreditCardIcon,
  },
  {
    id: 'decoupled_stripe_credit_card_rg_live',
    label: 'Debit/Credit Card',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: CreditCardIcon,
  },
  {
    id: 'decoupled_stripe_credit_card_rg_test',
    label: 'Debit/Credit Card',
    mode: PAYMENT_MODE_TEST,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: CreditCardIcon,
  },
  {
    id: 'paypal_live',
    label: 'PayPal',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'paypal_ec',
    icon: PayPalIcon,
  },
  {
    id: 'paypal_test',
    label: 'PayPal',
    mode: PAYMENT_MODE_TEST,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'paypal_ec',
    icon: PayPalIcon,
  },
  {
    id: 'paypal_rg_live',
    label: 'PayPal',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'paypal_ec',
    icon: PayPalIcon,
  },
  {
    id: 'paypal_rg_test',
    label: 'PayPal',
    mode: PAYMENT_MODE_TEST,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'paypal_ec',
    icon: PayPalIcon,
  },
  {
    id: 'decoupled_stripe_button_apple_pay_live',
    label: 'Apple Pay',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: ApplePayIcon,
  },
  {
    id: 'decoupled_stripe_button_apple_pay_test',
    label: 'Apple Pay',
    mode: PAYMENT_MODE_TEST,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: ApplePayIcon,
  },
  {
    id: 'decoupled_stripe_button_apple_pay_rg_live',
    label: 'Apple Pay',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: ApplePayIcon,
  },
  {
    id: 'decoupled_stripe_button_apple_pay_rg_test',
    label: 'Apple Pay',
    mode: PAYMENT_MODE_TEST,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: ApplePayIcon,
  },
  {
    id: 'decoupled_stripe_button_google_pay_live',
    label: 'Google Pay',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: GooglePayIcon,
  },
  {
    id: 'decoupled_stripe_button_google_pay_test',
    label: 'Google Pay',
    mode: PAYMENT_MODE_TEST,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: GooglePayIcon,
  },
  {
    id: 'decoupled_stripe_button_google_pay_rg_live',
    label: 'Google Pay',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: GooglePayIcon,
  },
  {
    id: 'decoupled_stripe_button_google_pay_rg_test',
    label: 'Google Pay',
    mode: PAYMENT_MODE_TEST,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: GooglePayIcon,
  },
  {
    id: 'decoupled_stripe_revolut_live',
    label: 'Revolut Pay',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: RevolutIcon,
  },
  {
    id: 'decoupled_stripe_revolut_test',
    label: 'Revolut Pay',
    mode: PAYMENT_MODE_TEST,
    donation_type: [SD],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: RevolutIcon,
  },
  {
    id: 'decoupled_stripe_revolut_rg_live',
    label: 'Revolut Pay',
    mode: PAYMENT_MODE_LIVE,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: RevolutIcon,
  },
  {
    id: 'decoupled_stripe_revolut_rg_test',
    label: 'Revolut Pay',
    mode: PAYMENT_MODE_TEST,
    donation_type: [RG],
    region: ['IE', 'GB'],
    type: 'credit_card',
    icon: RevolutIcon,
  },
];

/**
 * TODO: Move it to Constants to avoid loading Payment Icons for Gifts.
 * Determine payment mode.
 */
let paymentMode;
if (ENVIRONMENT !== 'production') {
  paymentMode = PAYMENT_MODE_TEST;
} else {
  // Check for special local storage value - use 'test' payment mode if it is present.
  try {
    const { sessionStorage } = window;
    if (sessionStorage.getItem(PAYMENT_SECRET_HEADER_NAME)) {
      paymentMode = PAYMENT_MODE_TEST;
    } else {
      paymentMode = PAYMENT_MODE_LIVE;
    }
  } catch (e) {
    // Access denied - sessionStorage is disabled.
    paymentMode = PAYMENT_MODE_LIVE;
  }
}
export const PAYMENT_MODE = paymentMode;

/**
 * Checks if Stripe payment button is available on the device.
 *
 * @param stripeButtonAvailability
 *  The result of https://stripe.com/docs/stripe-js/reference#payment-request-can-make-payment API call.
 * @param wallet
 *  Name of specific wallet, e.g. 'applePay' or 'googlePay'. If not passed,
 *  all wallets will be checked.
 *
 * @returns {boolean}
 *  True if specified wallet is supported on the device.
 */
const isStripeButtonAvailable = (stripeButtonAvailability, wallet = '') => {
  let availability = stripeButtonAvailability;
  if (PAYMENT_MODE !== PAYMENT_MODE_LIVE && typeof window !== 'undefined') {
    // In test mode allow overriding availability in session storage.
    availability =
      availability || window.sessionStorage.getItem(`${PAYMENT_SECRET_HEADER_NAME}-Stripe-Button`);
  }

  if (!wallet) {
    return !!availability;
  }

  return availability && availability[wallet];
};
export { isStripeButtonAvailable };

/**
 * Get payment methods filtered by mode, donation type, region, and
 * Stripe button availability (Apple Pay).
 *
 * @param mode
 *   Payment method mode.
 *
 * @param donationType
 *   Donation type.
 *
 * @param region
 *   Either IE or GB region.
 *
 * @param stripeButtonAvailability
 *  The result of https://stripe.com/docs/stripe-js/reference#payment-request-can-make-payment API call.
 *
 * @param showRevolutStripe
 *  The indicator defined visibility of revolut payment method.
 *
 * @returns {*[]}
 */
export const filterActiveMethods = ({
  mode,
  donationType,
  region,
  stripeButtonAvailability,
  showRevolutStripe = false,
}) => {
  return methods.filter(
    (el) =>
      el.mode === mode &&
      el.donation_type.includes(donationType) &&
      el.region.includes(region) &&
      (isStripeButtonAvailable(stripeButtonAvailability, 'googlePay') ||
        !el.id.startsWith('decoupled_stripe_button_google')) &&
      (isStripeButtonAvailable(stripeButtonAvailability, 'applePay') ||
        !el.id.startsWith('decoupled_stripe_button_apple')) &&
      (showRevolutStripe || !el.id.startsWith('decoupled_stripe_revolut')),
  );
};

export const getStripePublishableKey = () =>
  PAYMENT_MODE === PAYMENT_MODE_LIVE
    ? stripePublicKeys[PAYMENT_MODE_LIVE]
    : stripePublicKeys[PAYMENT_MODE_TEST];

export const getGiftsStripePublishableKey = () =>
  PAYMENT_MODE === PAYMENT_MODE_LIVE
    ? stripeGiftsPublicKeys[PAYMENT_MODE_LIVE]
    : stripeGiftsPublicKeys[PAYMENT_MODE_TEST];

/**
 * Defines array that will be sent to the backend in case of
 * recurring donation.
 */
export const PAYPAL_SUBSCRIPTION_PAYLOAD = {
  billing_plan: {
    name: 'Monthly donation for Concern Worldwide',
    description: 'Monthly donation for Concern Worldwide.',
    type: 'INFINITE',
    payment_definitions: [
      {
        name: 'Monthly donation',
        type: 'REGULAR',
        frequency: 'MONTH',
        frequency_interval: 1,
        cycles: 0,
      },
    ],
    merchant_preferences: {
      auto_bill_amount: 'NO',
      initial_fail_amount_action: 'CONTINUE',
      max_fail_attempts: 0,
    },
  },
  billing_agreement: {
    name: 'Monthly donation for Concern Worldwide',
    description: 'Monthly donation for Concern Worldwide.',
  },
};

/**
 * Defines array that will be sent to the backend in case of
 * single donation.
 */
export const PAYPAL_DONATION_PAYLOAD = {
  transactions: [
    {
      description: 'Single donation for Concern Worldwide.',
    },
  ],
};
