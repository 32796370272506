import { END } from 'redux-saga';
import dynamic from 'next/dynamic';
import * as transformsSettings from '../utils/transforms.settings';
import * as transformsPageTypes from '../utils/transforms.page_types';
import {
  getNormalizedEntityURL,
  getDrupalEntityFromRoute,
  validateAndProcessPageContentResponse,
} from '../routing';
import * as field from '../utils/transforms.fields';
import {
  globalSettingsSaveFooterSettings,
  globalSettingsSavePrimaryNavigation,
  globalSettingsSaveGeneralSettings,
  globalSettingsSaveHeaderSettings,
  globalSettingsSaveHomepageLink,
  globalSettingsSaveTopBanner,
} from '../store/Slices/cnet/globalSettingsSlice';
import { PAGE_LAYOUTS, COOKIEBOT_ID } from '../utils/constants';
import { addPicturePreload } from '../utils/picture';
import { getCountryByHost } from '../utils/language';

// Load layout dynamically to avoid loading global styles for Gifts app.
const CNETLayout = dynamic(() => import('./cnet_layout'));

// Internal debugging.
const debug = require('debug')('cw:cnet_app.js');

const CNETApplication = (props) => <CNETLayout {...props} />;

CNETApplication.getInitialProps = async ({
  Component,
  store,
  ctx: { res, req, query, ...ctx },
  ...appProps
}) => {
  // Remove legacy router prop passed to the app. Use withRouter() HOC
  // if you need router API in your component.
  const { router, ...props } = appProps;

  let initialProps = {
    entity: null,
    blocks: [],
    thankYouBlocks: [],
    thankYouBlocksRG: [],
    metatags: [],
    breadcrumbs: [],
    pageType: null,
    pageTypeSettings: [],
    forceBasicHeader: false,
    headerTags: null,
    language: getCountryByHost(req),
    // After next.js update to 13.5, `router.asPath` value is not available
    // on the server side anymore. `req.originalUrl` is the replacement we
    // found for our use cases.
    originalUrl: req ? req.originalUrl : ctx.asPath,
  };

  initialProps.layout = PAGE_LAYOUTS[ctx.pathname];

  // If it's a backend request then settings should come from the backend.
  // We put the data into the redux store so that later we can take it
  // from store instead of making additional backend requests.
  if (res && res.settings) {
    const settings = res.settings;

    try {
      const headerSettings = transformsSettings.header(settings);
      store.dispatch(globalSettingsSaveHeaderSettings(headerSettings));
    } catch (error) {
      debug('Could not transform header settings. Error: %O', error);
    }

    try {
      const generalSettings = transformsSettings.general(settings);
      // Fallback for Cookiebot on main website.
      if (initialProps.language === 'en' && COOKIEBOT_ID && !generalSettings.cookiebotId) {
        generalSettings.cookiebotId = COOKIEBOT_ID;
      }
      store.dispatch(globalSettingsSaveGeneralSettings(generalSettings));
    } catch (error) {
      debug('Could not transform general settings. Error: %O', error);
    }

    try {
      const footerSettings = transformsSettings.footer(settings);
      store.dispatch(globalSettingsSaveFooterSettings(footerSettings));
    } catch (error) {
      debug('Could not transform footer settings. Error: %O', error);
    }

    try {
      const primaryNavigation = transformsSettings.primaryNavigation(settings);
      store.dispatch(globalSettingsSavePrimaryNavigation(primaryNavigation));
    } catch (error) {
      debug('Could not transform primary navigation. Error: %O', error);
    }

    try {
      const topBanner = transformsSettings.topBanner(settings);
      store.dispatch(globalSettingsSaveTopBanner(topBanner));
    } catch (error) {
      debug('Could not transform top banner data. Error: %O', error);
    }

    if (res.homepageLink) {
      store.dispatch(globalSettingsSaveHomepageLink(res.homepageLink));
    }
  }

  // Get navigation and home page link data from Redux store.
  const { globalSettings } = store.getState();
  const { primaryNavigation, homepageLink } = globalSettings;
  initialProps.globalSettings = globalSettings;

  // The flag isAppOnlyRoute appears automatically in the query if the
  // current page was found in the internal routing. See ./routing/routes.js.
  const isAppRoute = query.isAppOnlyRoute ? query.isAppOnlyRoute : false;

  // The flag is true if this is standard Next.js error page.
  const isErrorPage = ctx.pathname === '/_error';

  // If the current page is not the frontend only route and not an error page,
  // then handle content from Drupal route.
  if (!isAppRoute && !isErrorPage) {
    try {
      // If it is a backend response, then entity object must be a part of
      // response. See decoupledRouter() for details.
      if (res && res.entity) {
        initialProps.entity = res.entity;
      } else {
        // Make a request to the backend from the browser to obtain the
        // entity object located under the given URL.
        const url = ctx.asPath || ctx.pathname;
        const normalizedURL = getNormalizedEntityURL(url, homepageLink);
        // If no URL is returned it means that the redirect has occurred,
        // so no need to process the page request any further.
        if (!normalizedURL) {
          initialProps.statusCode = 301;
          return initialProps;
        }
        const response = await getDrupalEntityFromRoute(normalizedURL, initialProps.language);

        // eslint-disable-next-line max-len
        const { statusCode, entity, entityURL } = validateAndProcessPageContentResponse(
          response,
          normalizedURL,
          res,
        );

        // Redirect to the right page.
        if (statusCode >= 300 && statusCode < 400 && entityURL) {
          // TODO: implement redirect without page reload.
          // We need to have the full entity for redirect without page reload.
          // In current version of routing we have just redirect or entity.
          debug(
            'Entity URL does not match the requested URL. Redirecting from %s to %s.',
            normalizedURL,
            entityURL.url,
          );
          window.location = entityURL.url;
          initialProps.statusCode = statusCode;
          return initialProps;
        }

        initialProps.statusCode = statusCode;
        initialProps.entity = entity;
      }
    } catch (error) {
      initialProps.statusCode = 502;
      debug('Error during Drupal backend request. Error: %O', error);
    }
  }

  // Pass entity, paragraph, metatags, and page type as props.
  if (initialProps.entity) {
    const { entity } = initialProps;
    try {
      const TransformBlocks = (await import('../utils/transforms.blocks')).default;
      const transformBlocks = new TransformBlocks();

      // Transform paragraphs on the backend into body blocks on the frontend.
      const blocks = field.getArrayValue(initialProps.entity, 'field_blocks');
      initialProps.blocks = transformBlocks.transform(entity, blocks, globalSettings, {
        layout: initialProps.layout,
      });

      // All transformations should happen in _app.js to reduce bundle sizes
      // of individual pages.
      const thankYouBlocks = field.getArrayValue(initialProps.entity, 'field_thankyou_page_blocks');
      if (thankYouBlocks.length) {
        initialProps.thankYouBlocks = transformBlocks.transform(
          entity,
          thankYouBlocks,
          globalSettings,
        );
      }
      // Body blocks for Thank you page for monthly donation.
      const thankYouBlocksRG = field.getArrayValue(
        initialProps.entity,
        'field_thankyou_page_blocks_rg',
      );
      if (thankYouBlocksRG.length) {
        initialProps.thankYouBlocksRG = transformBlocks.transform(
          entity,
          thankYouBlocksRG,
          globalSettings,
        );
      }

      // Get tags data from the entity and pass as props.
      if (entity.field_meta_tags) {
        initialProps.metatags = entity.field_meta_tags;
      }

      // Handling Page type which is represented by ECK entity.
      const pageType = field.getArrayValue(entity, 'field_page_type');
      if (pageType.length) {
        // Getting page type bundle.
        initialProps.pageType = field.getTextValue(pageType[0], 'entity_bundle');
        // Getting page type settings of corresponding bundle.
        initialProps.pageTypeSettings = transformsPageTypes[initialProps.pageType](
          pageType[0],
          globalSettings,
        );
      }

      // Render a basic header for Appeal pages and Donation landing page.
      // If a page has any type of Hero BB with Money Handles, it's a
      // donation landing page so has to have basic header as well.
      // Also render a basic header for /donate page.
      // TODO - eventually this will be defined on content level, see #166352194
      initialProps.forceBasicHeader =
        field.getEntityBundleLabel(initialProps.entity) === 'Appeal' ||
        ctx.asPath.split('?')[0] === '/donate' ||
        initialProps.blocks.filter((block) =>
          ['appeals_hero', 'donation_hero', 'hero_money_handles_form'].includes(block.blockType),
        ).length > 0;

      // Add Emergency tag label to the header in case if hero_money_handles_form
      // configured with 'emergency' or 'dec' style.
      const heroWithMoneyHandleFormBlock = initialProps.blocks.find(
        (block) => block.blockType === 'hero_money_handles_form',
      );
      if (
        heroWithMoneyHandleFormBlock &&
        (heroWithMoneyHandleFormBlock.styling === 'emergency' ||
          heroWithMoneyHandleFormBlock.styling === 'dec') &&
        heroWithMoneyHandleFormBlock.emergencyTagLabel
      ) {
        initialProps.headerTags = [
          { label: 'Emergency', backgroundColor: 'red', isUppercase: true },
          {
            label: heroWithMoneyHandleFormBlock.emergencyTagLabel,
            backgroundColor: 'black-emergency',
            isUppercase: true,
          },
        ];
      }

      // Preload images from other blocks if they are first on the page.
      if (initialProps.blocks.length) {
        const firstBlock = initialProps.blocks[0];

        // Add preload for inline_image body block.
        if (
          firstBlock.blockType === 'inline_image' &&
          firstBlock.rows.length &&
          firstBlock.rows[0].length &&
          firstBlock.rows[0][0].previewImage
        ) {
          initialProps.blocks[0].rows[0][0].previewImage = addPicturePreload(
            firstBlock.rows[0][0].previewImage,
            // Don't need to preload inline image on mobile devices,
            // because it isn't large content on a page.
            // So we don't want LCP degradation on mobile devices.
            ['min-xs', 'ss'],
          );
        }
      }
    } catch (error) {
      debug('Could not transform entity. Error: %O', error);
      // TODO: Important! In case of transform error we shouldn't continue the process normally,
      //   we should return server error or something similar.
    }
  }

  // Build breadcrumbs automatically using current entity path (if available) and
  // primary menu navigation.
  try {
    // eslint-disable-next-line max-len
    initialProps.breadcrumbs = field.getEntityBreadcrumb(
      initialProps.entity,
      primaryNavigation,
      homepageLink,
    );
  } catch (error) {
    debug('Could not get breadcrumbs. Error: %O', error);
  }

  // Call to getInitialProps() from the Page component if exists.
  if (Component.getInitialProps && initialProps.statusCode !== 404) {
    try {
      // Merge original props with props returned from page component.
      const childInitialProps = await Component.getInitialProps({
        store,
        res,
        query,
        router,
        ...initialProps,
        ...props,
        ...ctx,
      });
      initialProps = { ...initialProps, ...childInitialProps };
    } catch (e) {
      initialProps.statusCode = 502;
      debug('Error during getting initial props from the page component. Error: %O', e);
    }
  }
  if (req && store) {
    store.dispatch(END);
    await store.sagaTask.toPromise();
  }

  // Add status code from response if it was not set explicitly.
  if (!initialProps.statusCode && res) {
    res.statusCode = res.statusCode || 200;
    initialProps.statusCode = res.statusCode;
  }

  // If no errors occurred, we assume that everything went well.
  // Handle the case when an error page is navigated to on the FE via Back, because we cannot
  // get any initialProps nor res object.
  // We choose it to be 404 because;
  // 1) we cannot know what the original error page was.
  // 2) in normal use cases, it almost certainly was a 404, since they then clicked the home page
  // button.
  // Also see cnet/components/01_atoms/ErrorOverlay/index.js
  initialProps.statusCode =
    isErrorPage && !initialProps.statusCode ? 404 : initialProps.statusCode || 200;

  // Delete data which was already processed at this stage so that
  // it does not get into the HTML source of the page.
  if (initialProps.entity) {
    delete initialProps.entity.field_blocks;
    delete initialProps.entity.field_thankyou_page_blocks;
    delete initialProps.entity.field_meta_tags;
  }

  return initialProps;
};

export default CNETApplication;
