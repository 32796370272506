import { DEFAULT_CURRENCY } from '../currency';
import { isCustomPrice, isFreeProduct } from './products';
// import * as productUtils from './products';

// /**
//  * Return list of products in the basket
//  * for the current site currency.
//  */
// export const getProducts = (products, currentCurrency) => {
//   if (!products.length) {
//     return [];
//   }
//
//   // Remove products without price in the current currency
//   // from the list.
//   return products.filter((product) => {
//     if (productUtils.isCustomPrice(product.data)) {
//       return true;
//     }
//     return product.data.price[currentCurrency] !== undefined;
//   });
// };

// /**
//  * Returns quantity of the given product
//  * in the basket.
//  */

export const getProductQuantity = (product) => parseInt(product.quantity, 0);
//
// /**
//  * Returns price of the given product
//  * in the current site currency.
//  */
// export const getProductPrice = (product, currentCurrency) => {
//   let amount;
//   if (productUtils.isCustomPrice(product.data)) {
//     amount = product.data.price.amount;
//   } else {
//     amount = product.data.price[currentCurrency].amount;
//   }
//   return parseFloat(amount);
// };
//
// export const getProductSubtotal = (product, currentCurrency) => {
//   const subtotal = getProductPrice(product, currentCurrency) * getProductQuantity(product);
//   return parseFloat(subtotal);
// };
//
/**
 * Calculates total price of all products
 * in the basket.
 */
export const getTotal = (products) => {
  return products.reduce((total, product) => {
    const quantity = getProductQuantity(product);
    const price = product.data.price[DEFAULT_CURRENCY].amount;
    return total + price * quantity;
  }, 0);
};

/**
 * Returns total amount of products (with quantity)
 * in the basket.
 */
export const getCountProducts = (products) => {
  return products.reduce((itemsCount, product) => itemsCount + product.quantity, 0);
};

// /**
//  * Checks if the current product has delete button.
//  */
export const hasDeleteButton = (product) => !isFreeProduct(product);

/**
 * Checks if the current product has Quantity widget.
 */
export const hasQuantityWidget = (product) =>
  !isCustomPrice(product) && !isFreeProduct(product);
//
// /**
//  * Return custom donation if user has it in basket, or undefined.
//  */
// export const getCustomDonation = (products) =>
//   products.find((product) => productUtils.isCustomPrice(product.data));

/**
 * Filters out free products and returns prepared basket messages based on
 * free product configuration.
 */
export const getOfferings = (products, freeProducts) => {
  const total = getTotal(products);
  return freeProducts
    .filter(
      (freeProduct) =>
        freeProduct.automaticallyAddToBasket &&
        // Filter by current currency.
        freeProduct.amount.currency === DEFAULT_CURRENCY &&
        // Filter by non-empty offering configuration.
        freeProduct.threshold &&
        freeProduct.basketMessage &&
        freeProduct.enjoyMessage &&
        // Total above threshold for this free product amount.
        total >= freeProduct.threshold.amount,
    )
    .map((freeProduct) => {
      const newFreeProduct = { ...freeProduct };
      // Replace %MORE-AMOUNT% token by real value before rendering.
      const moreValue = freeProduct.amount.amount - total;
      if (total < newFreeProduct.amount.amount) {
        newFreeProduct.message = newFreeProduct.basketMessage.replace('%MORE-AMOUNT%', moreValue);
      } else {
        newFreeProduct.message = newFreeProduct.enjoyMessage;
      }
      return newFreeProduct;
    });
};
