const { ENVIRONMENT } = require('./constants');

const getCountryByHost = (req) => {
  let host;
  if (req) {
    host = req.headers.host;
  } else {
    host = window.location.host;
  }

  if (host.split('.').length > 2 && host.split('.')[0].length === 2) {
    return host.split('.')[0];
  }

  // Edge case for dev envs for AWS.
  if (
    ENVIRONMENT === 'development' &&
    host.includes('.systemseed.com') &&
    host.split('-').length > 2 &&
    host.split('-')[0].length === 2
  ) {
    return host.split('-')[0];
  }

  return 'en';
};

/**
 * Different countires (sub-domains) may use different site-settings.
 */
const getSiteSettingsByCountry = (country) => {
  if (country === 'en') {
    return '/config_pages/concern_site_settings';
  }

  return `/config_pages/concern_site_settings_${country}`;
};

module.exports = {
  getCountryByHost,
  getSiteSettingsByCountry,
};
