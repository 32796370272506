import * as field from '../transforms.fields';
import htmlDecode from '../htmlDecode';

/**
 * Transforms site wide header settings (menus, logo, etc)
 * into Styleguide-readable format.
 */
export const general = async (settings) => {
  const TransformBlocks = (await import('../transforms.blocks')).default;
  const transformBlocks = new TransformBlocks();
  const props = {};

  // Transform main menu.
  const fieldHeaderMenu = field.getObjectValue(settings, 'field_menu_primary');
  const headerPrimaryMenu = field.getArrayValue(fieldHeaderMenu, 'links');
  props.header = {
    leftText: field.getTextValue(settings, 'field_config_header_left_text'),
    rightText: field.getTextValue(settings, 'field_config_header_right_text'),
    menu: headerPrimaryMenu
      .map((menuItem) => ({
        uuid: menuItem.uuid,
        title: menuItem.title,
        url: (menuItem.url && menuItem.url.url) || '/',
      }))
      .filter((item) => !!item.url),
  };

  const fieldFooterMenu = field.getObjectValue(settings, 'field_footer_menu');
  const footerMenu = field.getArrayValue(fieldFooterMenu, 'links');
  props.footer = {
    leftText: field.getTextValue(settings, 'field_config_footer_left_text'),
    topText: field.getTextValue(settings, 'field_config_footer_top_text'),
    menu: footerMenu
      .map((menuItem) => ({
        uuid: menuItem.uuid,
        title: menuItem.title,
        url: (menuItem.url && menuItem.url.url) || '/',
      }))
      .filter((item) => !!item.url),
  };

  props.regionSettings = {
    switcher: field.getBooleanValue(settings, 'field_enable_region_switcher'),
    isRegionPopupEnabled: field.getBooleanValue(settings, 'field_enable_multi_region_popup'),
    title: field.getTextValue(settings, 'field_multi_region_popup_title'),
    description: field.getTextValue(settings, 'field_multi_region_popup_content'),
    regions: field.getArrayValue(settings, 'field_gifts_regions').map((item) => ({
      image: field.getImage(item, 'field_icon'),
      label: field.getTextValue(item, 'field_label'),
      code: field.getTextValue(item, 'field_gifts_country_code'),
      link: field.getLinkValue(item, 'field_region_url'),
    })),
  };

  const redirects = field.getArrayValue(settings, 'field_gifts_redirects');
  props.redirects =
    redirects.map((redirect) => ({
      fromUrl: field.getObjectValue(redirect, 'field_url_from').url.url,
      toUrl: field.getObjectValue(redirect, 'field_url_to').url.url,
      statusCode: field.getTextValue(redirect, 'field_status_code'),
    })) || [];

  props.frontPage = {
    homepageLink: field.getLinkValue(settings, 'field_frontpage').nextLink,
  };

  props.basketPage = {
    bestSellerGiftsTitle: field.getTextValue(settings, 'field_bestseller_items_title'),
  };

  // Prepare tooltips configuration.
  const tooltips = {};
  field
    .getTextValue(settings, 'field_config_checkout_tooltips')
    .split('\n')
    .forEach((item) => {
      const values = item.split('|');
      if (values.length === 2) {
        tooltips[values[0]] = values[1].replace('&#13;', '');
      }
    });

  props.checkoutPage = {
    title: field.getTextValue(settings, 'field_config_checkout_page_title'),
    cardsTitle: field.getTextValue(settings, 'field_cards_title'),
    cardsDescription: htmlDecode(
      field.getTextValue(settings, 'field_config_checkout_card'),
    ).replace(/\n/g, '<br>'),
    cardsPostTitle: field.getTextValue(settings, 'field_post_card_title'),
    cardsPostDescription: htmlDecode(
      field.getTextValue(settings, 'field_post_card_description'),
    ).replace(/\n/g, '<br>'),
    cardsEmailToRecipientTitle: field.getTextValue(settings, 'field_to_recipient_card_title'),
    cardsEmailToRecipientDescription: htmlDecode(
      field.getTextValue(settings, 'field_to_recipient_card_descr'),
    ).replace(/\n/g, '<br>'),
    cardsEmailToYourselfTitle: field.getTextValue(settings, 'field_to_yourself_card_title'),
    cardsEmailToYourselfDescription: htmlDecode(
      field.getTextValue(settings, 'field_to_yourself_card_descr'),
    ).replace(/\n/g, '<br>'),
    optinsTitle: field.getTextValue(settings, 'field_optins_title'),
    optinsTopDescription: field.getTextValue(settings, 'field_config_checkout_optins'),
    optinsBottomDescription: field.getTextValue(settings, 'field_config_checkout_optins_btm'),
    giftAidTitle: field.getTextValue(settings, 'field_giftaid_title'),
    giftAidCheckboxLabel: field.getTextValue(settings, 'field_giftaid_checkbox_label'),
    giftAidImage: field.getImage(settings, 'field_giftaid_media_image', 'gifts_giftaid'),
    tooltips,
    sourceCodes: field.getArrayValue(settings, 'field_source_codes').filter((code) => {
      if (!code.startsAt || code.startsAt <= Date.now() / 1000) {
        return true;
      }

      return false;
    }),
    sourceCodesCorporate: field
      .getArrayValue(settings, 'field_source_codes_corporate')
      .filter((code) => {
        if (!code.startsAt || code.startsAt <= Date.now() / 1000) {
          return true;
        }

        return false;
      }),
  };

  props.giftDetailPage = {
    relatedGiftsTitle: field.getTextValue(settings, 'field_gifts_related_gifts_title'),
  };

  const labels = field.getArrayValue(settings, 'field_config_corporate_labels');
  const images = field.getArrayValue(settings, 'field_config_corporate_images');

  props.corporateGiftPage = {
    labels: labels.map((item) => item.value),
    images: images.map((item) => field.getImage(item, 'field_media_image', 'gifts_width_1920')),
  };

  props.catalogUrl = field.getLinkValue(settings, 'field_banner_url').nextLink;

  const thankYouPageBlocks = field.getArrayValue(settings, 'field_thank_you_page_body_blocks');
  props.thankYouPage = {
    blocks: transformBlocks.transform(settings, thankYouPageBlocks, props, {}),
  };

  const notFoundPageBlocks = field.getArrayValue(settings, 'field_page_not_found_body_blocks');
  props.notFoundPage = {
    blocks: transformBlocks.transform(settings, notFoundPageBlocks, props, {}),
  };

  // General flag whether to show captcha or not for webforms.
  props.generalSettings = {};
  props.generalSettings.showWebformsCaptcha = field.getBooleanValue(
    settings,
    'field_webform_show_captcha',
  );

  return props;
};
